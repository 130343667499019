import React, { useState, useEffect } from "react"
import ReactHowler from 'react-howler'
import FullScreenVideo from './full_screen_video';
import { youtube_parser } from "../components/common/utils";

const PodcastButton = (props) => {

    //console.log("Modules", props.Modules);
    const [isPlay, setPlay] = useState(false);
    const [show, setShow] = useState(false);
    const [play, setPlayvideo] = useState(true);
    const [showHTMLVideo, setHTMLVideo] = React.useState(false);
    const [mute, setMute] = useState(true);

    const playvideo = (ref) => {
        setPlayvideo(true)
    }
    const Show_Video = (boolean) => {
        setShow(boolean)
    }

    const Show_HTML_Video = (boolean) => {
        setHTMLVideo(boolean)
    }

    var Modules = props.Modules;

    var youtube_url = Modules.Embed_Video_Link && youtube_parser(Modules.Embed_Video_Link);
    var video_url = "https://www.youtube-nocookie.com/embed/"+youtube_url

    return( 
        <>
          {
            Modules.Embed_Video_Link ? (
              <>
                <a to={ "javascript:;"} className="btn btn-secondary podcast_btn" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                    <span>{show ? "Pause" : "Play"} {Modules.Audio_Title}</span>
                </a>

                {
                    play && (
                        <FullScreenVideo url={video_url} show={show} mute={mute} play={play} Show_HTML_Video={Show_HTML_Video}  Show_Video={Show_Video} />
                    )
                }
                
              </>
            ) : (
                Modules.Audio_File.url && (
                <>
                    <a to={ "javascript:;"} className="btn btn-secondary podcast_btn" onClick={()=> {setPlay(!isPlay)}}>
                        <span>{isPlay ? "Pause" : "Play"} {Modules.Audio_Title}</span>
                    </a>
                    {
                        isPlay && (
                            <ReactHowler
                                src={Modules.Audio_File.url}
                                playing={isPlay}
                            />
                        )
                    }
                    
                </>
                )
            )
          }
        </>
        
    )
}

export default PodcastButton