import React, { useState, useEffect } from "react"
import * as Icon from 'react-bootstrap-icons';
import { Container, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import ScrollAnimation from 'react-animate-on-scroll';
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
  } from "react-device-detect";

const FullScreenVideo = (props) => {

    //console.log("Modules", props.Modules);

    return (
         <div className="animated">
            <Modal
                show={props.show}
                onHide={() => props.Show_Video(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ReactPlayer url={props.url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? props.mute : false} playsinline={true} loop={true} playing={props.play} onEnded={() => { props.Show_HTML_Video(false) }} width='100%' height='100%' />
                </Modal.Body>
            </Modal>
         </div>

    )
}

export default FullScreenVideo