import { Link } from "@StarberryUtils";
//import * as React from "react"
import React, { useState, useEffect } from 'react';
import {Button, Container, Row,Col } from 'react-bootstrap';
import Slider from 'react-slick';
// images
//import googleReview from "../../../images/home/google-reviews_new.svg"
import GoogleReview from "../../../images/reviews-logo-color.png"
// import reviewer01 from "../../../images/home/reviewer01.jpg"
// import reviewer02 from "../../../images/home/reviewer02.jpg"
// import reviewer03 from "../../../images/home/reviewer03.jpg"
// import reviewer04 from "../../../images/home/reviewer04.jpg"
// Style
import  './Reviews.scss';
// import ScrollAnimation from 'react-animate-on-scroll';

import wordsToNumbers from 'words-to-numbers';
import ShowMoreText from 'react-show-more-text';

const postsPerPage = 5;
let arrayForHoldingPosts = [];

const Reviews = ( props ) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(20);

  const [] = useState(true);
  const [testimonials, setTestimonials] = useState([])

  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, [testimonials]);

  useEffect(() => {
    setTestimonials(props.testimonials);
  }, [props.testimonials])

  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials ? testimonials.slice(start, end) : {};
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  });
    const settingsThumbs = {
        dots: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.slider-for',
        focusOnSelect: true,
      }
      const settingsMain = {
        dots: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.slider-nav'
      }

    var testimonial_count = props.testimonials ? props.testimonials.length : 0;
    var rating_count = 0;
    props.testimonials && props.testimonials.length > 0 && props.testimonials.map((item, ind)=>{
        rating_count += parseInt(wordsToNumbers(item.starRating));
    })
    var rating_avg = rating_count/testimonial_count;

    //console.log("postsToShow", postsToShow);
return(
<div className="review-blogs text-center">
  <div className="review-block-section-title">
     <h2>The Client Testimonials of The Pearl Gates Real Estate Agents in Qatar</h2>
  </div>

  <Container>
    <Row>
        <Col>
        {/*  <div className="animated"> */}
            <Slider className="review-slider" {...settingsMain} asNavFor={nav2} ref={slider => (setSlider1(slider))} >
                {postsToShow.map((item, index) => {
                    if(item.comment){
                        return(
                            <div className="review-info">
                                <div className="d-md-flex text-left justify-content-center align-items-center">
                                    <Link className="google-logo" to="/about/reviews/"><img src={GoogleReview} alt="Google" /></Link>
                                    <div className="review-detail d-none d-md-block">
                                        <h6 className="total-review">{parseFloat(rating_avg).toFixed(1)} Rating from {testimonial_count} Customers</h6>   
                                        <span className="total-desc"><Link to="/about/reviews">View more reviews</Link></span> 
                                    </div>
                                </div>
                                <h2 className="review-title">
                                    <ShowMoreText
                                        lines={2}
                                        more=''
                                        less=''
                                        className='content-css'
                                        anchorClass='btn-more review-more'
                                        expanded={false}
                                        width={0}> 
                                        {item.comment}
                                    </ShowMoreText>
                                </h2>
                                <span className="prof-name" review_id={item.reviewId}>{item.reviewer.displayName}</span>
                            </div>
                        ) 
                    }
                                   
                })
                }

            </Slider>
            <Slider className="profile-slider" {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                {postsToShow.map((item, index) => {
                    return(
                    <div className="profile-item">
                        <img src={item.reviewer.profilePhotoUrl} alt="Image"/>
                    </div>
                    )                
                })
                }
            </Slider>
            {/*  </div> */}
        </Col>
    </Row>
  </Container>
  

</div>
)
}



export default Reviews