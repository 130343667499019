import { Link } from "@StarberryUtils";
import * as React from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import dateFormat from 'dateformat';

// images
import ImgPerson from "../../../images/person.jpg"
import blogImg1 from "../../../images/home/blog-img-01.jpg"
import blogImg2 from "../../../images/home/blog-img-02.jpg"
import blogImg3 from "../../../images/home/blog-img-03.jpg"
import ContactCard from "../../ContactCard/ContactCard"
// Style
import './LatestBlogs.scss';
import ScrollAnimation from 'react-animate-on-scroll';

import { LatestBlogs } from "../../../queries/common_use_query"


const LatestBlogsComponent = (props) => {

  const { loading, error, data } = LatestBlogs();

  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst:true,
    centerMode: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint:1199,
         settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
    },
      {
          breakpoint: 767,
           settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
              }
      },
      {
        breakpoint:99999,
         settings: {
                slidesToShow: 2,
                infinite: false,
                dots: false,
                centerMode: false,
                variableWidth: true,
            }
    },
  ]
}

 const result = data?.insights.filter(item => item.Tag === props.Filter_by_Tag);
 let datatoshow = props.isCommunity ? result : data?.insights?.slice(0,8)
  return (
    <div className="latest-blogs text-center text-xl-left">
      <Container>
        <Row>
        {props.Filter_by_Tag === "The Pearl" || props.Filter_by_Tag === "Lusail City" || props.Filter_by_Tag === "Gewan Island" ?
           <Col xl={4}>
             {/*  <div className="animated"> */}
                 
             { props.Filter_by_Tag === "The Pearl" ?
               <strong className="sub-title">The Pearl Community Blogs and Articles</strong> : props.Filter_by_Tag === "Lusail City" ?
               <strong className="sub-title">Lusail Community Blogs and Articles</strong> : props.Filter_by_Tag === "Gewan Island" ?
               <strong className="sub-title">Gewan Island Community Blogs and Articles</strong> : ""}
               <h2 className="main-title">The insider’s view of Qatari property</h2>
               <p className="main-desc d-none d-md-block">With deep experience in the Qatar real estate market, we thrive on our insider knowledge. The Pearl Gates shares valuable insights into the local property scene. </p>
               <Link to={'/about/case-studies-and-insights'} className="btn btn-primary d-none d-xl-inline-block"><span>View More</span></Link>
             {/*  </div> */}
           </Col> :
       
          <Col xl={4}>
            {/*  <div className="animated"> */}
              <strong className="sub-title">{props.Insights_Block?.Small_Text}</strong>
              <h2 className="main-title">{props.Insights_Block?.Title}</h2>
              <p className="main-desc d-none d-md-block">{props.Insights_Block?.Content}</p>
              <Link to={props.Insights_Block?.CTA_1_Link} className="btn btn-primary d-none d-xl-inline-block"><span>{props.Insights_Block?.CTA_1_Label}</span></Link>            
            {/*  </div> */}
          </Col>}
          <Col xl={8}>
            <Slider {...settings} >
              {
                datatoshow?.map((item, index) => {
                  var date_of_item = item.Date && dateFormat(item.Date, "dd mmmm yyyy");
                  var split_tags = item.Tag ? item.Tag.split(', ') : '';

                  return (
                    //  <div className="animated">
                      <ContactCard
                        cardImg={item.Tile_Image}
                        cardTitle={item.Title}
                        date={date_of_item}
                        cardTag={item.Tag ? split_tags[0] : item.Category.replace('_', ' ')}
                        link_url={item.URL}
                        imagename={"insights.Tile_Image.tile_image"}
                        article_id={item.id} 
                        imagetransforms={item.imagetransforms}
                        externalLink={item.External_Link}
                        section="latest_blogs"
                      />
                    //  </div>
                  )
                })
              }
            </Slider>
            <Link to={'/about/case-studies-and-insights'} className="btn btn-primary d-xl-none"><span>View More</span></Link>
          </Col>
          {/*  </div> */}
        </Row>
      </Container>


    </div>
  )
}



export default LatestBlogsComponent