import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./AccordionBlock.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"

import HTMLReactParser from 'html-react-parser';

const AccordionBlock = props => {
  const [activeId, setActiveId] = useState(props.activeId ? props.activeId : "0");
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    setViewMore(props.ViewMore)
  },[props.ViewMore])

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId("")
    } else {
      setActiveId(id)
    }
  }

  //console.log("ViewMore", props.ViewMore, viewMore)

  return (
    <div className={`accordion-block ${props.Collection === "Community" ? 'comm_accr' : ''}`}>

        <Row>
          <Col lg={12}>
            {
              props.Accordian_Title && 
              <div className="animated">
                <h2>{props.Accordian_Title}</h2>
              </div>
            }
            

            
              <Accordion defaultActiveKey={activeId}>
                {
                  props.AccordionItems && props.AccordionItems.length > 0 && props.AccordionItems.map((item,index) => {

                    return(
                      <Card>
                        <Accordion.Toggle onClick={() => toggleActive(index.toString())} className={`accordion-header`} eventKey={index.toString()}>
                          {item.Title}
                          <i className={ activeId === index.toString() ? "icon-minus-accordion" : "icon-plus-accordion"}></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                          <div className="accordion-card">
                            <p>{item.Content && HTMLReactParser(item.Content)}</p>
                          </div>
                        </Accordion.Collapse>
                      </Card>
                    )
                  })
                }
              </Accordion>
            

            {
              viewMore && (
                 <div className="animated">
                  <div className="btn-view-wrap">
                    <Link to={props.ViewMoreLink} className="btn btn-view-more">
                      {props.ViewMoreLabel}
                    </Link>
                  </div>
                </div>
              )
            }
            
          </Col>
        </Row>

    </div>
  )
}

export default AccordionBlock
